
import { System } from '@/modules/system';
import { Clinic } from '@/modules/clinic'
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IResearch, ILaboratory, IManipulation } from '../types';
import { CustomerLinks, IPatient } from '@/modules/customers/types';

@Component
export default class ResearchDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly item: IResearch | undefined;

  system = $app.module(System);
  clinic = $app.module(Clinic);

  labs: Array<ILaboratory> = [];
  manipulations: Array<IManipulation> = [];
  allowed: Array<IManipulation> = [];
  loading = false;
  findPatient = false;

  @Watch('value')
  onShow() {
    this.manipulations = [];
    this.allowed = [];

    if (this.value) {
      this.reload();
    }
  }

  @Watch('item')
  onItemChanged() {
    if (this.value) {
      this.reloadAllowed();
    }
  }

  getDoctorName(id: number) {
    return id ? $app.label('DoctorsGlossary', id) : ''
  }

  async reload() {
    this.loading = true;
    try {
      const response = await $app.post('/api/clinics/labs/search', { search: '', append: true, itemsPerPage: 25, page: 1, totals: 0 });
      this.labs = response.items;

      if (this.item && this.item.id) {
        this.manipulations = await $app.get('/api/clinics/researches/' + this.item.id + '/manipulations');
      }
    } catch (err) {
      $app.pushError(err);
    }
    this.loading = false;

    this.reloadAllowed();
  }

  async reloadAllowed() {
    if (this.item && this.item.id && this.item.state === 'N' && this.allowed.length === 0) {
      this.loading = true;
      try {
        this.allowed = await $app.get('/api/clinics/researches/' + this.item.id + '/allowed');
      } catch (err) {
        $app.pushError(err);
      }
      this.loading = false;
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get manipulationsEditable() {
    return this.item &&
      this.item.state === 'N';
  }

  get invalid() {
    return !this.item ||
      this.item.patient === null ||
      this.item.laboratory === null ||
      this.item.sended === null ||
      this.item.dueDate === null;
  }

  get readonly() {
    return !this.item ||
      this.item.state === 'P' ||
      this.item.state === 'C';
  }

  get manipulationsList(): Array<IManipulation> {
    this.allowed.forEach(m => { m.processed = false });
    this.manipulations.forEach(m => { m.processed = true });
    return this.manipulations.concat(this.allowed).filter(m => m.state !== 'C');
  }

  goToPatient() {
    if (this.item) {
      if (this.item.patient && this.item.id) {
        if (this.$route.name !== 'CustomerEdit') {
          const route = this.$router.resolve({ name: CustomerLinks.CustomerEdit, params: { EntityId: '' + this.item!.patient } })
          window.open(route.href, '_blank');
        }
      } else {
        this.findPatient = true;
      }
    }
  }

  selectPatient(patient: IPatient) {
    if (this.item) {
      this.item.patient = patient.patientId;
      this.item.patientName = patient.patientLastName + ' ' + patient.patientFirstName;
      this.item.patientPhone = patient.patientPhone;
      this.item.patientPhoto = patient.photo;
    }
    this.findPatient = false;
  }

  async addManipulation(item: IManipulation) {
    if (this.item) {
      item.processing = true;
      try {
        await $app.post('/api/clinics/researches/' + this.item.id + '/manipulations/' + item.id, {});
        item.processed = true;
        const index = this.allowed.findIndex(m => m.id === item.id);
        if (index >= 0) {
          this.allowed.splice(index, 1);
        }
        this.manipulations.push(item);
      } catch (err) {
        $app.pushError(err);
      }
      item.processing = false;
    }
  }

  async removeManipulation(item: IManipulation) {
    if (this.item) {
      item.processing = true;
      try {
        await $app.delete('/api/clinics/researches/' + this.item.id + '/manipulations/' + item.id);
        item.processed = false;
        const index = this.manipulations.findIndex(m => m.id === item.id);
        if (index >= 0) {
          this.manipulations.splice(index, 1);
        }
        this.allowed.push(item);
      } catch (err) {
        $app.pushError(err);
      }
      item.processing = false;
    }
  }

  store() {
    this.$emit('store', this.item);
  }

  remove() {
    this.$emit('remove', this.item);
  }

  setState(state: string) {
    this.$emit('state', state);
  }

  save(state: string) {
    Promise.all([
      this.$emit('store', this.item),
      this.$emit('state', state)
    ]);
  }

  labChange() {
    if (this.item) {
      const lab = this.labs.find(l => l.id === this.item!.laboratory);
      if (lab) {
        this.item.labName = lab.name;
      }
    }
  }

  async makeReport() {
    if (this.item) {
      window.open(window.location.origin + '/reports?report=Research&ResearchId=' + this.item.id, '_blank');
    }
  }
}
